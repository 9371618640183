<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="info" tile v-bind="attrs" v-on="on" class="mt-4"
          >apply now</v-btn
        >
      </template>
      <v-card>
        <p class="text-end pa-3">
          <v-icon color="blue darken-1" @click="dialog = false"
            >mdi-close</v-icon
          >
        </p>
        <v-card-title class="mt-n4">
          <h2>Registration</h2>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="dform" lazy-validation @submit.prevent>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.fullname"
                    :rules="nameRules"
                    hint="Surname First"
                    persistent-hint
                    required
                  >
                    <template #label>
                      Full Name<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.email"
                    :rules="emailRules"
                    required
                  >
                    <template #label>
                      Email<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <!-- <v-col cols="12">
                  <v-text-field
                    v-model="dform.passwd"
                    :rules="messageRules"
                    required
                    :type="showPassword ? 'text' : 'password'"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                  >
                  <template #label>
                     Password<span class="error--text">*</span>
                  </template>
                  </v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.dob"
                    type="date"
                    :rules="messageRules"
                    required
                  >
                    <template #label>
                      Date of Birth<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.phone"
                    :rules="phoneRules"
                    maxlength="11"
                    counter="11"
                    required
                  >
                    <template #label>
                      Mobile Number<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model.trim="dform.religion"
                    :items="religions"
                    :rules="messageRules"
                    required
                  >
                    <template #label>
                      Religion<span class="error--text">*</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="dform.gender"
                    :items="genders"
                    :rules="messageRules"
                    required
                  >
                    <template #label>
                      Gender<span class="error--text">*</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.address"
                    :rules="messageRules"
                  >
                    <template #label>
                      Address<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.track"
                    readonly
                    :rules="messageRules"
                  >
                    <template #label>
                      Track<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model.trim="dform.package"
                    :items="packages"
                    :rules="messageRules"
                  >
                    <template #label>
                      Package<span class="error--text">*</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <p><span class="error--text">*</span> indicates required field</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            class="ma-4"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>

          <v-btn
            class="ma-4"
            :loading="loading"
            color="info"
            @click="submitForm"
            :disabled="!isValid"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { apiClient } from "@/services";
import { snackMixin, rulesMixin } from "@/mixins";
export default {
  name: "TrainingForm",
  props: {
    track: {
      type: String,
    },
  },
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      dform: this.initializeForm(),
      dialog: false,
      showPassword: false,
    };
  },

  computed: {
    ...mapState(["packages", "religions", "genders"]),
    isValid() {
      const reqf = [
        "fullname",
        "phone",
        "email",
        "gender",
        "dob",
        "address",
        "track",
        "package",
        "religion",
      ];
      return reqf.every((v) => !!this.dform[v]);
    },
  },

  methods: {
    submitForm() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      apiClient
        .post("/trainees", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = this.initializeForm();
            this.displayMsg(
              "Registration has been submitted successfully, We will get back to you"
            );
            this.loading = false;
            this.$refs.dform.resetValidation();
            this.dialog = false;
          } else {
            this.displayMsg(res.data, "error");
          }
        })
        .catch((error) => {
          this.displayMsg(error.message, "error");
          // this.displayMsg(error.response.data.errorMessage, "error");
          this.dialog = false;
        });
    },

    initializeForm() {
      return {
        fullname: "",
        email: "",
        phone: "",
        dob: "",
        address: "",
        track: this.track,
        package: "",
        religion: "",
        gender: "",
      };
    },
  },
};
</script>
