<template>
  <div>
    <v-row class="pt-4 breadcrumb" justify="center">
      <v-col cols="12">
        <h1 class="font-weight-bold text-center text-h4 mt-4 white--text">
          Building Tech Leaders
        </h1>
        <br />
        <p class="white--text text-center">
          Our training programs are structured according to industry knowledge
          experts, <br />
          creating opportunies for learners, students and youths in our society
        </p>
      </v-col>
    </v-row>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
      <v-row class="mt-7">
        <v-col
          v-for="prog in progs"
          :key="prog.title"
          cols="12"
          sm="6"
          md="4"
          class="mx-auto"
        >
          <v-hover v-slot="{ hover }">
            <v-card height="550" width="500" :elevation="hover ? 4 : 2">
              <v-card flat>
                <v-img
                  :src="`/img/home/${prog.src}`"
                  height="100"
                  width="100"
                  class="mx-auto mt-4"
                >
                </v-img>
              </v-card>

              <div class="ml-4">
                <h3 class="mt-8 primary--text">{{ prog.title }}</h3>
                <p class="mt-2">{{ prog.text }}</p>

                <div v-if="prog.available">
                  <h3 class="info--text mt-2">Pricing</h3>
                  <!-- <h4 class="mt-2 error--text">
                    0 - 1 month: NGN{{ prog.one }}
                  </h4> -->
                  <h4 class="mt-2 error--text">
                    3 months: NGN{{ prog.half }}
                  </h4>
                  <h4 class="mt-2 error--text">
                    5 months: NGN{{ prog.full }}
                  </h4>
                  <TrainingForm :track="prog.title" />
                </div>
                <div v-else>
                  <h4 class="error--text">Coming Soon...</h4>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TrainingForm from "@/components/TrainingForm.vue";
export default {
  name: "Training",
  components: {
    TrainingForm,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "Training",
          disabled: true,
        },
      ],

      progs: [
        {
          src: "software.png",
          title: "SOFTWARE DEVELOPMENT",
          text: ` Looking forward to become a developer, transit into a career in tech or simply boost your career path, we offer Software  develoment Programs across the three tracks: Front-End, Back-End and FullStack. `,
          half: " 100,000",
          full: " 150,000",
          available: true,
        },

        {
          src: "ux.jpg",
          title: "UI/UX DESIGN",
          text: "A magnificent UI goes beyond capturing the user’s attention, creating outstanding UX, and building the business brand.Choose a career path in ui/ux design to become a professional Product designer using our training guidelines",
          half: " 100,000",
          full: " 150,000",
          available: true,
        },
        {
          src: "graphics.png",
          title: "GRAPHICS DESIGN",
          text: "To better understand the meaning of graphic design, it is important to be aware of the elements and principles that make up design. Elements are used in conjunction or opposition with each other to create visually striking and impactful designs.",
          half: " 100,000",
          full: " 150,000",
          available: true,
        },
        // {
        //   src: "project.png",
        //   title: "PROJECT MANAGEMENT",
        //   text: "Our Outlines include Project Management Techniques, Data Handling and Decision Making, Project Analytics and Quality Management. At the end of this programme You will be able to develop your confidence in managing a team and executing projects of all sizes ",
        //   one: " 30,000",
        //   half: " 60,000",
        //   full: " 110,000",
        //   available: false,
        // },

        {
          src: "hardware.png",
          title: "HARDWARE MAINTANCE & REPAIR",
          text: "Dive your way into learning how to  identifying, troubleshooting and resolving problems and issues in a faulty computer",
          half: " 100,000",
          full: " 150,000",
          available: true,
        },
        {
          src: "data.png",
          title: "DATA SCIENCE/ANALYSIS",
          text: "Data Science/Analysis describes a series of techniques aimed at extracting relevant and valuable information from extensive and diverse sets of data gathered from different sources and varying in sizes. Apply to get started",
          half: " 120,000",
          full: " 180,000",
          available: true,
        },
        // {
        //   src: "automation.jpg",
        //   title: "IoT AND IT AUTOMATION",
        //   text: "Learn your way into technologies designed to facilitate the user’s life, automating specific processes, allowing remote control, simplifying plant management, and generally optimizing the management of the environment.",
        //   one: " 30,000",
        //   half: " 60,000",
        //   full: " 100,000",
        //   available: true,
        // },
        {
          src: "security.png",
          title: "CYBER SECURITY",
          text: "Learn and understand how to use technologies, processes and controls to protect computers, servers, devices, electronic systems, networks, programs and data from malicious cyber attacks.",
          half: " 120,000",
          full: " 180,000",
          available: true,
        },
      ],
    };
  },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
